/* eslint-disable perfectionist/sort-objects */
import type { CustomThemeConfig } from '@skeletonlabs/tw-plugin';

export const tickrrDarkTheme: CustomThemeConfig = {
	// Note: this value can NOT be changed without changing the corresponding 'data-theme' value in src/app.html.
	name: 'tickrr-dark-theme',
	properties: {
		// =~= Theme Properties =~=
		'--theme-font-family-base': 'system-ui',
		'--theme-font-family-heading': 'system-ui',
		'--theme-font-color-base': 'var(--color-surface-800)',
		'--theme-font-color-dark': 'var(--color-surface-100)',
		'--theme-rounded-base': '8px',
		'--theme-rounded-container': '12px',
		'--theme-border-base': '2px',
		// =~= Theme On-X Colors =~=
		'--on-primary': 'var(--color-surface-900)',
		'--on-secondary': 'var(--color-surface-50)',
		'--on-tertiary': 'var(--color-surface-50)',
		'--on-success': 'var(--color-surface-900)',
		'--on-warning': 'var(--color-surface-900)',
		'--on-error': 'var(--color-surface-50)',
		'--on-surface': 'var(--color-tertiary-100)',
		// =~= Theme Colors  =~=
		// primary | #00ffaa
		'--color-primary-50': '217 255 242', // #d9fff2
		'--color-primary-100': '204 255 238', // #ccffee
		'--color-primary-200': '191 255 234', // #bfffea
		'--color-primary-300': '153 255 221', // #99ffdd
		'--color-primary-400': '77 255 196', // #4dffc4
		'--color-primary-500': '0 255 170', // #00ffaa
		'--color-primary-600': '0 230 153', // #00e699
		'--color-primary-700': '0 191 128', // #00bf80
		'--color-primary-800': '0 153 102', // #009966
		'--color-primary-900': '0 125 83', // #007d53
		// secondary | #005BE7
		'--color-secondary-50': '217 230 251', // #d9e6fb
		'--color-secondary-100': '204 222 250', // #ccdefa
		'--color-secondary-200': '191 214 249', // #bfd6f9
		'--color-secondary-300': '153 189 245', // #99bdf5
		'--color-secondary-400': '77 140 238', // #4d8cee
		'--color-secondary-500': '0 91 231', // #005BE7
		'--color-secondary-600': '0 82 208', // #0052d0
		'--color-secondary-700': '0 68 173', // #0044ad
		'--color-secondary-800': '0 55 139', // #00378b
		'--color-secondary-900': '0 45 113', // #002d71
		// tertiary | #2e2e2e (SAME AS SURFACE)
		'--color-tertiary-50': '237 237 237', // #ededed
		'--color-tertiary-100': '161 161 161', // #a1a1a1
		'--color-tertiary-200': '143 143 143', // #8f8f8f
		'--color-tertiary-300': '135 135 135', // #878787
		'--color-tertiary-400': '69 69 69',
		'--color-tertiary-500': '36 36 36', // #242424
		'--color-tertiary-600': '26 26 26', // #1a1a1a
		'--color-tertiary-700': '16 16 16', // #101010
		'--color-tertiary-800': '10 10 10', // #0a0a0a
		'--color-tertiary-900': '0 0 0', // #000000
		// success | #00ffaa
		'--color-success-50': '217 255 242', // #d9fff2
		'--color-success-100': '204 255 238', // #ccffee
		'--color-success-200': '191 255 234', // #bfffea
		'--color-success-300': '153 255 221', // #99ffdd
		'--color-success-400': '77 255 196', // #4dffc4
		'--color-success-500': '0 255 170', // #00ffaa
		'--color-success-600': '0 230 153', // #00e699
		'--color-success-700': '0 191 128', // #00bf80
		'--color-success-800': '0 153 102', // #009966
		'--color-success-900': '0 125 83', // #007d53
		// warning | #fff952
		'--color-warning-50': '255 254 229', // #fffee5
		'--color-warning-100': '255 254 220', // #fffedc
		'--color-warning-200': '255 254 212', // #fffed4
		'--color-warning-300': '255 253 186', // #fffdba
		'--color-warning-400': '255 251 134', // #fffb86
		'--color-warning-500': '255 249 82', // #fff952
		'--color-warning-600': '230 224 74', // #e6e04a
		'--color-warning-700': '191 187 62', // #bfbb3e
		'--color-warning-800': '153 149 49', // #999531
		'--color-warning-900': '125 122 40', // #7d7a28
		// error | #ff3d64
		'--color-error-50': '255 226 232', // #ffe2e8
		'--color-error-100': '255 216 224', // #ffd8e0
		'--color-error-200': '255 207 216', // #ffcfd8
		'--color-error-300': '255 177 193', // #ffb1c1
		'--color-error-400': '255 119 147', // #ff7793
		'--color-error-500': '255 61 100', // #ff3d64
		'--color-error-600': '230 55 90', // #e6375a
		'--color-error-700': '191 46 75', // #bf2e4b
		'--color-error-800': '153 37 60', // #99253c
		'--color-error-900': '125 30 49', // #7d1e31
		// // error | #E1162A
		// '--color-error-50': '251 220 223', // #fbdcdf
		// '--color-error-100': '249 208 212', // #f9d0d4
		// '--color-error-200': '248 197 202', // #f8c5ca
		// '--color-error-300': '243 162 170', // #f3a2aa
		// '--color-error-400': '234 92 106', // #ea5c6a
		// '--color-error-500': '225 22 42', // #E1162A
		// '--color-error-600': '203 20 38', // #cb1426
		// '--color-error-700': '169 17 32', // #a91120
		// '--color-error-800': '135 13 25', // #870d19
		// '--color-error-900': '110 11 21', // #6e0b15
		// surface | #0A0A0A
		// '--color-surface-50': '218 218 218',
		// '--color-surface-100': '206 206 206',
		// '--color-surface-200': '194 194 194',
		// '--color-surface-300': '161 161 161',
		// '--color-surface-400': '84 84 84',
		// '--color-surface-500': '10 10 10',
		// '--color-surface-600': '8 8 8',
		// '--color-surface-700': '6 6 6',
		// '--color-surface-800': '3 3 3',
		// '--color-surface-900': '0 0 0'
		'--color-surface-50': '237 237 237', // #ededed
		'--color-surface-100': '161 161 161', // #a1a1a1
		'--color-surface-200': '143 143 143', // #8f8f8f
		'--color-surface-300': '135 135 135', // #878787
		'--color-surface-400': '69 69 69', // #696969
		'--color-surface-500': '36 36 36', // #242424
		'--color-surface-600': '26 26 26', // #1a1a1a
		'--color-surface-700': '16 16 16', // #101010
		'--color-surface-800': '10 10 10', // #0a0a0a
		'--color-surface-900': '0 0 0' // #000000
	}
};
