import { z } from 'zod';

export const AVAILABLE_ENDPOINTS = [
	'/stocks',
	'/forex_pairs',
	'/cryptocurrencies',
	'/etf',
	'/indices',
	'/funds',
	'/bonds',
	'/exchanges',
	'/cryptocurrency_exchanges',
	'/technical_indicators',
	'/symbol_search',
	'/earliest_timestamp',
	'/market_state',
	'/time_series',
	'/time_series',
	'/exchange_rate',
	'/currency_conversion',
	'/quote',
	'/price',
	'/eod',
	'/market_movers/stocks',
	'/market_movers/etf',
	'/market_movers/mutual_funds',
	'/market_movers/forex',
	'/market_movers/crypto',
	'/mutual_funds/list',
	'/mutual_funds/family',
	'/mutual_funds/type',
	'/mutual_funds/world',
	'/mutual_funds/world/summary',
	'/mutual_funds/world/performance',
	'/mutual_funds/world/risk',
	'/mutual_funds/world/ratings',
	'/mutual_funds/world/composition',
	'/mutual_funds/world/purchase_info',
	'/mutual_funds/world/sustainability',
	'/logo',
	'/profile',
	'/dividends',
	'/splits',
	'/earnings',
	'/earnings_calendar',
	'/ipo_calendar',
	'/statistics',
	'/insider_transactions',
	'/income_statement',
	'/balance_sheet',
	'/cash_flow',
	'/options/expiration',
	'/options/chain',
	'/options/expiration',
	'/key_executives',
	'/institutional_holders',
	'/fund_holders',
	'/direct_holders',
	'/last_change/:endpoint',
	'/earnings_estimate',
	'/revenue_estimate',
	'/eps_trend',
	'/eps_revisions',
	'/growth_estimates',
	'/recommendations',
	'/price_target',
	'/analyst_ratings/light',
	'/analyst_ratings/us_equities',
	'/quotes/price',
	'/complex_data',
	'/api_usage',
	'/ad',
	'/add',
	'/adosc',
	'/adx',
	'/adxr',
	'/apo',
	'/aroon',
	'/aroonosc',
	'/atr',
	'/avg',
	'/avgprice',
	'/bbands',
	'/beta',
	'/bop',
	'/cci',
	'/ceil',
	'/cmo',
	'/coppock',
	'/correl',
	'/crsi',
	'/dema',
	'/div',
	'/dpo',
	'/dx',
	'/ema',
	'/exp',
	'/floor',
	'/heikinashicandles',
	'/hlc3',
	'/ht_dcperiod',
	'/ht_dcphase',
	'/ht_phasor',
	'/ht_sine',
	'/ht_trendline',
	'/ht_trendmode',
	'/ichimoku',
	'/kama',
	'/keltner',
	'/kst',
	'/linearreg',
	'/linearregangle',
	'/linearregintercept',
	'/linearregslope',
	'/ln',
	'/log10',
	'/ma',
	'/macd',
	'/macd_slope',
	'/macdext',
	'/mama',
	'/max',
	'/maxindex',
	'/mcginley_dynamic',
	'/medprice',
	'/mfi',
	'/midpoint',
	'/midprice',
	'/min',
	'/minindex',
	'/minmax',
	'/minmaxindex',
	'/minus_di',
	'/minus_dm',
	'/mom',
	'/mult',
	'/natr',
	'/obv',
	'/percent_b',
	'/pivot_points_hl',
	'/plus_di',
	'/plus_dm',
	'/ppo',
	'/roc',
	'/rocp',
	'/rocr',
	'/rocr100',
	'/rsi',
	'/rvol',
	'/sar',
	'/sarext',
	'/sma',
	'/sqrt',
	'/stddev',
	'/stoch',
	'/stochf',
	'/stochrsi',
	'/sub',
	'/sum',
	'/supertrend',
	'/supertrend_heikinashicandles',
	'/t3ma',
	'/tema',
	'/trange',
	'/trima',
	'/tsf',
	'/typprice',
	'/ultosc',
	'/var',
	'/vwap',
	'/wclprice',
	'/willr',
	'/wma'
] as const;

export const TECHNICAL_INDICATORS = [
	'AD',
	'ADD',
	'ADOSC',
	'ADX',
	'ADXR',
	'APO',
	'AROON',
	'AROONOSC',
	'ATR',
	'AVG',
	'AVGPRICE',
	'BBANDS',
	'BETA',
	'BOP',
	'CCI',
	'CEIL',
	'CMO',
	'COPPOCK',
	'CORREL',
	'CRSI',
	'DEMA',
	'DIV',
	'DPO',
	'DX',
	'EMA',
	'EXP',
	'FLOOR',
	'HEIKINASHICANDLES',
	'HLC3',
	'HT_DCPERIOD',
	'HT_DCPHASE',
	'HT_PHASOR',
	'HT_SINE',
	'HT_TRENDLINE',
	'HT_TRENDMODE',
	'ICHIMOKU',
	'KAMA',
	'KELTNER',
	'KST',
	'LINEARREG',
	'LINEARREGANGLE',
	'LINEARREGINTERCEPT',
	'LINEARREGSLOPE',
	'LN',
	'LOG10',
	'MA',
	'MACD',
	'MACD SLOPE',
	'MACDEXT',
	'MAMA',
	'MAX',
	'MAXINDEX',
	'MCGINLEY_DYNAMIC',
	'MEDPRICE',
	'MFI',
	'MIDPOINT',
	'MIDPRICE',
	'MIN',
	'MININDEX',
	'MINMAX',
	'MINMAXINDEX',
	'MINUS_DI',
	'MINUS_DM',
	'MOM',
	'MULT',
	'NATR',
	'OBV',
	'PERCENT_B',
	'PIVOT_POINTS_HL',
	'PLUS_DI',
	'PLUS_DM',
	'PPO',
	'ROC',
	'ROCP',
	'ROCR',
	'ROCR100',
	'RSI',
	'RVOL',
	'SAR',
	'SAREXT',
	'SMA',
	'SQRT',
	'STDDEV',
	'STOCH',
	'STOCHF',
	'STOCHRSI',
	'SUB',
	'SUM',
	'SUPERTREND',
	'SUPERTREND_HEIKINASHICANDLES',
	'T3MA',
	'TEMA',
	'TRANGE',
	'TRIMA',
	'TSF',
	'TYPPRICE',
	'ULTOSC',
	'VAR',
	'VWAP',
	'WCLPRICE',
	'WILLR',
	'WMA'
] as const;

export default {
	AvailableEndpoint: z.enum(AVAILABLE_ENDPOINTS),

	InstrumentType: z.enum([
		'American Depositary Receipt',
		'Bond Fund',
		'Bond',
		'Closed-end Fund',
		'Common Stock',
		'Depositary Receipt',
		'Digital Currency',
		'ETF',
		'Exchange-Traded Note',
		'Global Depositary Receipt',
		'Index',
		'Limited Partnership',
		'Mutual Fund',
		'Physical Currency',
		'Preferred Stock',
		'REIT',
		'Right',
		'Structured Product',
		'Trust',
		'Unit',
		'Warrant'
	]),

	Interval: z
		.enum(['1day', '1h', '1min', '1month', '1week', '2h', '4h', '5min', '15min', '30min', '45min'])
		.describe("Filter by interval. Default: '1day'"),

	Quote: z.object({
		average_volume: z.coerce.number().optional(),
		change: z.coerce.number(),
		close: z.coerce.number(),
		currency: z.string().optional(),
		datetime: z.coerce.date(),
		exchange: z.string(),
		extended_change: z.string().optional(),
		extended_percent_change: z.string().optional(),
		extended_price: z.string().optional(),
		extended_timestamp: z.number().optional(),
		fifty_two_week: z.object({
			high: z.coerce.number(),
			high_change: z.coerce.number(),
			high_change_percent: z.coerce.number(),
			low: z.coerce.number(),
			low_change: z.coerce.number(),
			low_change_percent: z.coerce.number(),
			range: z.string()
		}),
		high: z.coerce.number(),
		is_market_open: z.boolean(),
		low: z.coerce.number(),
		mic_code: z.string().optional(),
		name: z.string(),
		open: z.coerce.number(),
		percent_change: z.coerce.number(),
		previous_close: z.coerce.number(),
		rolling_1d_change: z.coerce.number().optional(),
		rolling_7d_change: z.coerce.number().optional(),
		rolling_period_change: z.coerce.number().optional(),
		symbol: z.string(),
		timestamp: z.coerce.date(),
		volume: z.coerce.number().optional()
	}),

	TechnicalIndicator: z.enum(TECHNICAL_INDICATORS),

	TimeSeriesPoint: z.object({
		close: z.coerce.number(),
		datetime: z.coerce.date().transform((date) => Math.floor(date.getTime() / 1000)), // Lightweight charts expects UNIX timestamps (in seconds)
		high: z.coerce.number(),
		low: z.coerce.number(),
		open: z.coerce.number(),
		volume: z.coerce.number().default(0)
	})
};
