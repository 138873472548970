import type { TDTypes } from '@tickrr/twelvedata';
import type { IChartApi, UTCTimestamp } from 'lightweight-charts';

import { logger } from '@tickrr/lib/logger';
import { z } from 'zod';

import { tickrrDarkTheme } from '../../../../theme/theme.ts';

export type SupportedPriceSeriesType = 'area' | 'candlestick';
export type SupportedPriceSeries = LWAreaSeries | LWCandleStickSeries;
export type TDTimeSeriesMetaData = TDTypes.NotError<
	TDTypes.Responses['CoreEndpoints']['TimeSeries']
>['meta'];
export type LWAreaSeries = ReturnType<IChartApi['addAreaSeries']>;
export type LWCandleStickSeries = ReturnType<IChartApi['addCandlestickSeries']>;
export type LWVolumeSeries = ReturnType<IChartApi['addHistogramSeries']>;

export const LW_CHART_PAGE_SIZE = 500; // 500 data points (this is MAX value)
export const LW_LOAD_MORE_BREAKPOINT = 150; // Load more data when the user scrolls to within 150 data points of the end of the chart
export const LW_VOLUME_SERIES_HEIGHT = 0.25;

export const LW_CHART_HEIGHT = {
	num: 384,
	tw: 'h-96'
};

export const AVAILABLE_SERIES_TYPES: {
	icon: string;
	label: string;
	value: SupportedPriceSeriesType;
}[] = [
	{
		icon: 'tabler:chart-candle',
		label: 'Candlestick',
		value: 'candlestick'
	},
	{
		icon: 'tabler:chart-area',
		label: 'Area',
		value: 'area'
	}
];

export function generateChartColor(
	token: keyof (typeof tickrrDarkTheme)['properties'],
	opacity: number
) {
	const val = tickrrDarkTheme.properties[token];
	const asNums = val.split(' ');
	return `rgba(${asNums[0]}, ${asNums[1]}, ${asNums[2]}, ${opacity})`;
}

// export function generateChartColor(variant: 'green' | 'red' | 'surface', opacity: number) {
// 	if (variant === 'green') {
// 		return `rgba(75, 236, 164, ${opacity})`; // primmary-500
// 	}

// 	if (variant === 'red') {
// 		return `rgba(255, 61, 100, ${opacity})`; // error-500
// 	}

// 	if (variant === 'surface') {
// 		return `rgba(151, 161, 186, ${opacity})`; // surface-300
// 	}
// }

const lwPriceFormatter = Intl.NumberFormat('en-US', { currency: 'USD', style: 'currency' });
const lwPriceChangeFormatter = Intl.NumberFormat('en-US', {
	maximumFractionDigits: 2,
	minimumFractionDigits: 2,
	signDisplay: 'always',
	style: 'decimal'
});
const lwFloatFormatter = Intl.NumberFormat('en-US', {
	maximumFractionDigits: 2,
	minimumFractionDigits: 2
});
const lwPercentFormatter = Intl.NumberFormat('en-US', {
	maximumFractionDigits: 2,
	minimumFractionDigits: 2,
	signDisplay: 'auto',
	style: 'percent'
});
const lwPercentChangeFormatter = Intl.NumberFormat('en-US', {
	maximumFractionDigits: 2,
	minimumFractionDigits: 2,
	signDisplay: 'exceptZero',
	style: 'percent'
});
const lwVolumeFormatter = Intl.NumberFormat('en-US', {
	notation: 'compact',
	style: 'decimal'
});
const lwDateFormatter = new Intl.DateTimeFormat('en', {
	// Format dates like: 'March 1, 2022'
	day: 'numeric',
	month: 'long',
	year: 'numeric'
});

/**
 * Formats a value using a specified format. This helper is INCREDIBLY useful for working with our charting library.
 * @param type - The type of format to use (e.g., 'price', 'float', 'percent', 'volume').
 * @param value - The value to format.
 * @param fallback - The fallback value to use if the value is null or undefined *(must be a string, if provided)*.
 * @returns The formatted value.
 */
export function lwFormat(
	type:
		| 'float'
		| 'large_number'
		| 'percent'
		| 'percent_change'
		| 'price'
		| 'price_change'
		| 'volume',
	value: null | number | undefined,
	fallback?: string
) {
	switch (type) {
		case 'price':
			if (!value) return fallback ?? '0.00';
			return lwPriceFormatter.format(value);
		case 'price_change':
			if (!value) return fallback ?? '0.00';
			return lwPriceChangeFormatter.format(value);
		case 'float':
			if (!value) return fallback ?? '0.00';
			return lwFloatFormatter.format(value);
		case 'percent':
			if (!value) return fallback ?? '0.00%';
			return lwPercentFormatter.format(value);
		case 'percent_change':
			if (!value) return fallback ?? '0.00%';
			return lwPercentChangeFormatter.format(value);
		case 'volume':
			if (!value) return fallback ?? '0';
			return lwVolumeFormatter.format(value);
		case 'large_number':
			if (!value) return fallback ?? '0';
			return formatLargeNumber(value);
	}
}

/**
 * Converts a timestamp to milliseconds.
 * @param t - The timestamp to convert.
 * @returns The converted timestamp in milliseconds.
 */
export function toMs(t: UTCTimestamp | string): number {
	return z.coerce.number().parse(t) * 1000;
}

/**
 * Converts a UTC timestamp to an API date string.
 *
 * @param t - The UTC timestamp to convert (in seconds).
 * @returns An object containing the formatted date and time strings.
 */
export function toApiDateString(t: UTCTimestamp | undefined): {
	date: string;
	time: string;
} {
	try {
		if (!t) {
			throw new Error('Time is not defined. Cannot format date string.');
		}

		const inMs = toMs(t);
		const date = new Date(inMs);
		return {
			date: lwDateFormatter.format(date),
			time: date.toTimeString().slice(0, 8)
		};
	} catch (e) {
		logger.warn({ e }, 'Error formatting date string');
		return {
			date: '00-00-00',
			time: '00:00:00'
		};
	}
}

/**
 * Formats a date string to a quarter format.
 * @param d - The date string to be formatted (e.g., "2022-03-01").
 * @returns The formatted quarter string in the format "Q{quarter} '{year}".
 * @throws If the time is not defined, an error is thrown.
 */
export function datestringToQuarter(d: string | undefined): string {
	try {
		if (!d) {
			throw new Error('Time is not defined. Cannot format date string.');
		}

		const date = new Date(d);
		const month = date.getMonth();
		const year = date.getFullYear();
		const quarter = Math.floor(month / 3) + 1;
		return `Q${quarter} '${year.toString().slice(2)}`;
	} catch (e) {
		logger.warn({ e }, 'Error formatting date string');
		return 'Q0 0000';
	}
}

/**
 * Formats a large number by adding suffixes like 'M' for millions and 'B' for billions.
 * @param num - The number to be formatted.
 * @returns The formatted number with the appropriate suffix.
 */
export function formatLargeNumber(num: number) {
	let formatted: string;
	let suffix: string;

	// Trillions
	if (num >= 1e12) {
		const trillions = num / 1e12;
		formatted = new Intl.NumberFormat('en-US', {
			maximumFractionDigits: 2,
			minimumFractionDigits: 2
		}).format(trillions);
		suffix = 'T';
	}
	// Billions
	else if (num >= 1e9) {
		const billions = num / 1e9;
		formatted = new Intl.NumberFormat('en-US', {
			maximumFractionDigits: 2,
			minimumFractionDigits: 2
		}).format(billions);
		suffix = 'B';
	}
	// Millions
	else if (num >= 1e6) {
		// Number is in millions
		const millions = num / 1e6;
		formatted = new Intl.NumberFormat('en-US', {
			maximumFractionDigits: 2,
			minimumFractionDigits: 2
		}).format(millions);
		suffix = 'M';
	}
	// Other
	else {
		// Number is less than 1 million, handle as you prefer
		formatted = new Intl.NumberFormat('en-US').format(num);
		suffix = '';
	}

	return `${formatted}${suffix}`;
}
