import { z } from 'zod';

import { default as Common } from './Common';
export { Common };

export const Parameters = z.object({
	code: z
		.string()
		.optional()
		.describe('Filter by market identifier code (MIC) under ISO 10383 standard'),
	country: z.string().optional().describe('Filter by country name or alpha code'),
	currency_base: z.string().optional().describe('Filter by currency base'),
	currency_quote: z.string().optional().describe('Filter by currency quote'),
	date: z
		.string()
		.refine((date) => /^\d{4}-\d{2}-\d{2}$/.test(date), {
			message: "Date must be in the format 'YYYY-MM-DD'"
		})
		.optional()
		.describe('Filter by date'),
	delimiter: z
		.string()
		.optional()
		.describe("Specify the delimiter used when downloading the CSV file. Default: ';'"),
	dp: z
		.union([
			z.literal(0),
			z.literal(1),
			z.literal(2),
			z.literal(3),
			z.literal(4),
			z.literal(5),
			z.literal(6),
			z.literal(7),
			z.literal(8),
			z.literal(9),
			z.literal(10),
			z.literal(11)
		])
		.optional()
		.describe(
			'Specifies the number of decimal places for floating values, should be in range [0,11] inclusive. Default: 5'
		),
	end_date: z
		.string()
		.refine(
			(date) =>
				/^\d{4}-\d{2}-\d{2}$/.test(date) || /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/.test(date),
			{
				message: "Date must be in the format 'YYYY-MM-DD' or 'YYYY-MM-DD HH:MM:SS'"
			}
		)
		.optional()
		.describe('Filter by end date, can be used with OR without start_date'),
	exchange: z.string().optional().describe('Filter by exchange name or mic code'),
	format: z.enum(['CSV', 'JSON']).optional().describe("Value can be JSON or CSV. Default: 'JSON'"),
	include_delisted: z.boolean().optional().describe('Include delisted identifiers. Default: false'),
	interval: Common.Interval.optional(),
	mic_code: z
		.string()
		.optional()
		.describe('Filter by market identifier code (MIC) under ISO 10383 standard'),
	name: z.string().optional().describe('Filter by name of the instrument'),
	order: z.enum(['ASC', 'DESC']).optional().describe("The order of the records. Default: 'DESC'"),
	outputsize: z
		.number()
		.refine((size) => size > 0 && size <= 500, {
			message: 'Output size must be between 1 and 500'
		})
		.optional()
		.describe('The number of records to return. Default: 30. Max: 5000'),
	page: z.number().optional().describe('The number of the page to fetch. Default: 1'),
	prepost: z
		.string()
		.optional()
		.describe(
			'Only for Pro and above plans. Available at the 1min, 5min, 15min, and 30min intervals for all US equities. Open, high, low, close values are supplied without volume.'
		),
	range: z
		.enum(['1m', '1y', '2y', '3m', '5y', '6m', 'full', 'last', 'ytd'])
		.optional()
		.describe("Filter by range. Default: 'last'"),
	rating_change: z
		.enum(['Downgrade', 'Initiates', 'Maintains', 'Reiterates', 'Upgrade'])
		.optional()
		.describe(
			'Filter by rating change action: Maintains, Upgrade, Downgrade, Initiates or Reiterates'
		),
	show_plan: z
		.boolean()
		.optional()
		.describe('Display details regarding TwelveData API access to the endpoint'),
	start_date: z
		.string()
		.refine(
			(date) =>
				/^\d{4}-\d{2}-\d{2}$/.test(date) || /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/.test(date),
			{
				message: "Date must be in the format 'YYYY-MM-DD' or 'YYYY-MM-DD HH:MM:SS'"
			}
		)
		.optional()
		.describe('Filter by start date, can be used with OR without end_date'),
	symbol: z.string().optional().describe('Filter by symbol'),
	timezone: z
		.string()
		.optional()
		.describe("Filter by the timezone of the exchange. Default: 'Exchange'"),
	type: z.string().optional().describe('Filter by instrument type')
});
