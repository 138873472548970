import extend from 'just-extend';
import {
	type AreaSeriesPartialOptions,
	type CandlestickSeriesPartialOptions,
	ColorType,
	CrosshairMode,
	type DeepPartial,
	LastPriceAnimationMode,
	LineStyle,
	LineType,
	type TimeChartOptions
} from 'lightweight-charts';

import { type SupportedPriceSeriesType, generateChartColor } from './common';

export type SupportedPriceSeriesOptions =
	| AreaSeriesPartialOptions
	| CandlestickSeriesPartialOptions;

export type ChartConfig<T extends SupportedPriceSeriesOptions = SupportedPriceSeriesOptions> = {
	chart: DeepPartial<TimeChartOptions>;
	series: DeepPartial<T>;
};

/**
 *
 *
 * BASE CONFIGS
 *
 *
 */
export const BASE_CHART_CONFIG = {
	autoSize: true,
	crosshair: {
		horzLine: {
			labelBackgroundColor: generateChartColor('--color-surface-50', 1),
			labelVisible: true,
			visible: true
		},
		mode: CrosshairMode.Normal,
		vertLine: {
			labelBackgroundColor: generateChartColor('--color-surface-50', 1),
			labelVisible: true,
			visible: true
		}
	},
	grid: {
		horzLines: {
			color: generateChartColor('--color-surface-600', 1),
			style: LineStyle.Solid,
			visible: true
		},
		vertLines: {
			color: generateChartColor('--color-surface-600', 1),
			style: LineStyle.Solid,
			visible: true
		}
	},
	layout: {
		background: {
			color: generateChartColor('--color-surface-700', 1.0),
			type: ColorType.Solid
		},
		fontSize: 12,
		textColor: generateChartColor('--color-surface-100', 1)
	},
	rightPriceScale: {
		minimumWidth: 80
	},
	timeScale: {
		fixLeftEdge: true,
		fixRightEdge: false,
		minimumHeight: 28,
		ticksVisible: true,
		timeVisible: true,
		uniformDistribution: true,
		visible: true
	}
} satisfies DeepPartial<TimeChartOptions>;

export const SERIES_CONFIGS: Record<
	SupportedPriceSeriesType,
	DeepPartial<SupportedPriceSeriesOptions>
> = {
	area: {
		baseLineVisible: false,
		bottomColor: 'transparent',
		lastPriceAnimation: LastPriceAnimationMode.Continuous,
		lastValueVisible: true,
		lineColor: generateChartColor('--color-success-500', 1),
		lineType: LineType.Simple,
		lineWidth: 2,
		priceLineVisible: false,
		topColor: generateChartColor('--color-success-500', 0.4)
	} satisfies DeepPartial<AreaSeriesPartialOptions>,

	candlestick: {
		borderVisible: false,
		lastValueVisible: false,
		priceLineStyle: 4,
		priceLineVisible: false
		// downColor: generateChartColor('--color-error-500', 1.0),
		// upColor: generateChartColor('--color-success-500', 1.0),
		// wickDownColor: generateChartColor('--color-error-500', 1.0),
		// wickUpColor: generateChartColor('--color-success-500', 1.0)
	} satisfies DeepPartial<CandlestickSeriesPartialOptions>
};
/**
 *
 *
 * COMPONENT CONFIGS
 *
 *
 */

export const MINIMALIST_CHART_CONFIG: ChartConfig<AreaSeriesPartialOptions> = {
	chart: {
		autoSize: true,
		crosshair: {
			horzLine: {
				labelVisible: false,
				visible: false
			},
			vertLine: {
				labelVisible: false,
				visible: false
			}
		},
		grid: {
			horzLines: {
				visible: false
			},
			vertLines: {
				visible: false
			}
		},
		layout: {
			background: {
				bottomColor: 'transparent',
				topColor: 'transparent',
				type: ColorType.VerticalGradient
			},
			fontSize: 12,
			textColor: generateChartColor('--color-surface-300', 1)
		},
		rightPriceScale: {
			scaleMargins: {
				bottom: 0.1,
				top: 0.1
			},
			visible: false
		},
		timeScale: {
			barSpacing: 2,
			borderVisible: false,
			fixLeftEdge: true,
			fixRightEdge: true,
			uniformDistribution: true,
			visible: false
		}
	} satisfies DeepPartial<TimeChartOptions>,

	series: {
		...SERIES_CONFIGS.area
	}
};

export const MINI_AREA_CHART_CONFIG: ChartConfig<AreaSeriesPartialOptions> = {
	chart: {
		autoSize: true,
		crosshair: {
			horzLine: {
				labelVisible: false,
				visible: false
			},
			vertLine: {
				labelVisible: false,
				visible: false
			}
		},
		grid: {
			horzLines: {
				visible: false
			},
			vertLines: {
				visible: false
			}
		},
		layout: {
			background: {
				bottomColor: 'transparent',
				topColor: 'transparent',
				type: ColorType.VerticalGradient
			},
			fontSize: 12,
			textColor: generateChartColor('--color-surface-300', 1)
		},
		rightPriceScale: {
			scaleMargins: {
				bottom: 0.05,
				top: 0.05
			},
			visible: false
		},
		timeScale: {
			barSpacing: 2,
			borderVisible: false,
			fixLeftEdge: true,
			fixRightEdge: false, // this MUST be false in order to set rightOffset
			rightOffset: 10,
			uniformDistribution: true,
			visible: false
		}
	} satisfies DeepPartial<TimeChartOptions>,

	series: {
		...SERIES_CONFIGS.area
	}
};

export const INLINE_CHART_CONFIG: ChartConfig<AreaSeriesPartialOptions> = {
	chart: {
		autoSize: true,
		crosshair: {
			horzLine: {
				labelVisible: false,
				visible: false
			},
			mode: CrosshairMode.Hidden,
			vertLine: {
				labelVisible: false,
				visible: false
			}
		},
		grid: {
			horzLines: {
				visible: false
			},
			vertLines: {
				visible: false
			}
		},
		height: 20,
		layout: {
			background: {
				bottomColor: 'transparent',
				topColor: 'transparent',
				type: ColorType.VerticalGradient
			},
			fontSize: 12,
			textColor: generateChartColor('--color-surface-300', 1)
		},
		rightPriceScale: {
			scaleMargins: {
				bottom: 0.05,
				top: 0.05
			},
			visible: false
		},
		timeScale: {
			barSpacing: 2,
			borderVisible: false,
			fixLeftEdge: true,
			fixRightEdge: false, // this MUST be false in order to set rightOffset
			rightOffset: 10,
			uniformDistribution: true,
			visible: false
		}
		// width: 240 // FIXME:
	} satisfies DeepPartial<TimeChartOptions>,

	series: {
		baseLineVisible: false,
		bottomColor: 'transparent',
		lastPriceAnimation: LastPriceAnimationMode.Continuous,
		lastValueVisible: true,
		lineColor: generateChartColor('--color-surface-500', 1),
		lineType: LineType.Simple,
		lineWidth: 2,
		priceLineVisible: false,
		topColor: generateChartColor('--color-surface-500', 0.4)
	}
};

export const SYMBOL_PAGE_CHART_CONFIG: ChartConfig<AreaSeriesPartialOptions> = {
	chart: extend(true, {}, BASE_CHART_CONFIG, {
		autoSize: true,
		grid: {
			horzLines: {
				visible: false
			},
			vertLines: {
				visible: false
			}
		},
		layout: {
			background: {
				bottomColor: 'transparent',
				topColor: 'transparent',
				type: ColorType.VerticalGradient
			},
			fontSize: 12,
			textColor: generateChartColor('--color-surface-300', 1)
		},
		rightPriceScale: {
			minimumWidth: 0
		},
		timeScale: {
			fixLeftEdge: true,
			fixRightEdge: true,
			minimumHeight: 40,
			visible: true
		}
	} satisfies DeepPartial<TimeChartOptions>),

	series: {
		...SERIES_CONFIGS.area
	}
};
